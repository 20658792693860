
.currencyItem {
    display: flex;
    width: 161px;
    max-height: 152px;
    padding: 24px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    border: 1px solid rgba(247, 147, 26, 0.50);
    background: linear-gradient(224deg, rgba(247, 147, 26, 0.30) 0%, rgba(247, 147, 26, 0.19) 17.98%, rgba(247, 147, 26, 0.12) 37.95%, rgba(247, 147, 26, 0.08) 55.69%, rgba(247, 147, 26, 0.02) 80.88%, rgba(247, 147, 26, 0.00) 100%);
}

.currencyIcon {
    width: 48px;
    height: 48px;
}

.currency {
    color: var(--gray-dark, #ABABAB);
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.currencyName {
    color: var(--primary-black, #3F3F3F);
    font-family: var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.usdt {
    border: 1px solid rgba(27, 162, 122, 0.50);
    background: linear-gradient(224deg, rgba(27, 162, 122, 0.30) 0%, rgba(27, 162, 122, 0.19) 17.98%, rgba(27, 162, 122, 0.12) 37.95%, rgba(27, 162, 122, 0.08) 55.69%, rgba(27, 162, 122, 0.02) 80.88%, rgba(27, 162, 122, 0.00) 100%);
}

.eth {
    border: 1px solid rgba(84, 84, 84, 0.50);
    background: linear-gradient(224deg, rgba(84, 84, 84, 0.30) 0%, rgba(84, 84, 84, 0.19) 17.98%, rgba(84, 84, 84, 0.12) 37.95%, rgba(84, 84, 84, 0.08) 55.69%, rgba(84, 84, 84, 0.02) 80.88%, rgba(84, 84, 84, 0.00) 100%);
}

.jpy {
    border: 1px solid rgba(95, 72, 232, 0.50);
    background: linear-gradient(224deg, rgba(95, 72, 232, 0.30) 0%, rgba(95, 72, 232, 0.19) 20.00%, rgba(95, 72, 232, 0.12) 40.00%, rgba(95, 72, 232, 0.08) 60.00%, rgba(95, 72, 232, 0.02) 80.00%, rgba(95, 72, 232, 0.00) 100%);
}

.kpw {
    border: 1px solid rgba(232, 120, 72, 0.50);
    background: linear-gradient(224deg, rgba(232, 120, 72, 0.30) 0%, rgba(232, 120, 72, 0.19) 20.00%, rgba(232, 120, 72, 0.12) 40.00%, rgba(232, 120, 72, 0.08) 60.00%, rgba(232, 120, 72, 0.02) 80.00%, rgba(232, 120, 72, 0.00) 100%);
}

.trx {
    border: 1px solid rgba(235, 0, 14, 0.50);
    background: linear-gradient(224deg, rgba(235, 0, 14, 0.30) 0%, rgba(235, 0, 14, 0.19) 17.98%, rgba(235, 0, 14, 0.12) 37.95%, rgba(235, 0, 14, 0.08) 55.69%, rgba(235, 0, 14, 0.02) 80.88%, rgba(235, 0, 14, 0.00) 100%);
}

.usd {
    border: 1px solid rgba(30, 100, 140, 0.50);
    background: linear-gradient(224deg, rgba(30, 100, 140, 0.30) 0%, rgba(30, 100, 140, 0.19) 20.00%, rgba(30, 100, 140, 0.12) 40.00%, rgba(30, 100, 140, 0.08) 60.00%, rgba(30, 100, 140, 0.02) 80.00%, rgba(30, 100, 140, 0.00) 100%);
}

.gpb {
    border: 1px solid rgba(152, 29, 163, 0.50);
    background: linear-gradient(224deg, rgba(152, 29, 163, 0.30) 0%, rgba(152, 29, 163, 0.19) 20.00%, rgba(152, 29, 163, 0.12) 40.00%, rgba(152, 29, 163, 0.08) 60.00%, rgba(152, 29, 163, 0.02) 80.00%, rgba(152, 29, 163, 0.00) 100%);
}

.inr {
    border: 1px solid rgba(232, 206, 72, 0.50);
    background: linear-gradient(224deg, rgba(232, 206, 72, 0.30) 0%, rgba(232, 206, 72, 0.19) 17.98%, rgba(232, 206, 72, 0.12) 37.95%, rgba(232, 206, 72, 0.08) 55.69%, rgba(232, 206, 72, 0.02) 80.88%, rgba(232, 206, 72, 0.00) 100%);
}

.eur {
    border: 1px solid rgba(26, 152, 115, 0.50);
    background: linear-gradient(224deg, rgba(26, 152, 115, 0.30) 0%, rgba(26, 152, 115, 0.19) 20.00%, rgba(26, 152, 115, 0.12) 40.00%, rgba(26, 152, 115, 0.08) 60.00%, rgba(26, 152, 115, 0.02) 80.00%, rgba(26, 152, 115, 0.00) 100%);
}

.busd {
    border: 1px solid rgba(240, 185, 11, 0.50);
    background: linear-gradient(224deg, rgba(240, 185, 11, 0.30) 0%, rgba(240, 185, 11, 0.19) 17.98%, rgba(240, 185, 11, 0.12) 37.95%, rgba(240, 185, 11, 0.08) 55.69%, rgba(240, 185, 11, 0.02) 80.88%, rgba(240, 185, 11, 0.00) 100%);
}

.cny {
    border: 1px solid rgba(72, 232, 184, 0.50);
    background: linear-gradient(224deg, rgba(72, 232, 184, 0.30) 0%, rgba(72, 232, 184, 0.19) 20.00%, rgba(72, 232, 184, 0.12) 40.00%, rgba(72, 232, 184, 0.08) 60.00%, rgba(72, 232, 184, 0.02) 80.00%, rgba(72, 232, 184, 0.00) 100%);
}
