.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 90px auto;
    z-index: -1;
}

.hero,
.why,
.benefits,
.forWhom,
.currencies {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero::before {
    content: url("./../../../assets/img/new/hero_bg_first.svg");
    position: absolute;
    bottom: 200px;
    left: 0;
    z-index: -1;
    width: 401px;
    height: 355px;
}

.hero::after {
    content: url("./../../../assets/img/new/hero_bg_second.svg");
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    width: 503px;
    height: 315px;
}


.container {
    max-width: 1264px;
    padding: 0 16px;
    width: 100%;
}

.heroContentWrapper {
    padding: 75px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.heroInfoWrapper {
    width: 500px;
}

.heroTitle {
    color: var(--primary-orange);
    font-weight: 700;
    margin: 0;
}

.subtitle {
    color: var(--primary-black, #3F3F3F);
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 68px;
}

.description {
    color: var(--gray-dark, #ABABAB);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 32px 0 48px;
}
.DropDownWrapper {
    text-align: center;
    position: relative;
}

.DropDownList {
    position: absolute;
    display: block;
    width: 230px;
    padding-top: 5px;
    margin-top: 10px;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;
}
.NoneDropDown {
    display: none;
}
.DropDownListItem {
    width: 150px;
    margin: 0 auto 5px;
    padding: 10px 5px;
    border-radius: 20px;
}
.DropDownListItem:hover {
    background: #FEE7D5;
}

.fragmentOne,
.fragmentTwo,
.fragmentThree,
.fragmentFour {
    position: relative;

}

.fragmentOne::before {
    content: url("./../../../assets/img/new/fragment_one.svg");
    position: absolute;
    top: 0;
    left: 200px;
    z-index: -1;
    animation-name: firstAnimation;

    width: 457px;
    height: 455px;
    transform: rotate(210deg);
}

.fragmentTwo::before {
    position: absolute;
    z-index: -1;
    width: 365px;
    height: 442px;
    top: -240px;
    left: 111px;
    content: url("./../../../assets/img/new/fragment_two.svg");
    animation-name: secondAnimation;
    display: block;
    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentThree {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentFour {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.requestForm {
    margin: 0 auto;
}

@keyframes firstAnimation {
    to {
        transform: rotate(210deg)
    }
    from {
        transform: rotate(-150deg)
    }
}


@keyframes secondAnimation {
    to {
        transform: rotate(0)
    }
    from {
        transform: rotate(-360deg)
    }

}

.whyContentWrapper {
    padding: 88px 0 48px;
    display: flex;
    flex-direction: column;
}

.whyTitle {
    color: var(--primary-orange);
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
}

.sectionTitle {
    color: var(--primary-black);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 56px;
}

.infoWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: var(--secondary-font);;
}

.infoWrapperColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 608px;
}

.infoTitle {
    color: var(--primary-black, #3F3F3F);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.infoSubtitle {
    color: var(--gray-ultradark);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
}

.infoDescriptionWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
}

.lineWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--gray-ultradark, #797979);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.line {
    height: 2px;
    width: 25px;
    background-color: var(--primary-black);
}

.benefits::after {
    position: absolute;
    z-index: -1;
    width: 904px;
    height: 750px;
    right: 0;
    bottom: 0;
    content: url("./../../../assets/img/new/benefits_bg.svg");
}

.benefitsContentWrapper {
    padding: 88px 0 144px;
}

.blocksWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardItem {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 32px;
    border: 1px solid var(--primary-orange, #DF853B);
    background: linear-gradient(225deg, rgba(223, 133, 59, 0.30) 0%, rgba(223, 133, 59, 0.27) 16.67%, rgba(223, 133, 59, 0.18) 33.33%, rgba(223, 133, 59, 0.14) 50%, rgba(223, 133, 59, 0.09) 66.67%, rgba(223, 133, 59, 0.03) 83.33%, rgba(223, 133, 59, 0.00) 100%);
}

.number {
    color: var(--primary-orange, #DF853B);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

.textBlock {
    color: var(--primary-black, #3F3F3F);
    text-align: center;
    font-family: var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.forWhom {
    background-color: var(--primary-orange);
}

.forWhomContentWrapper {
    padding: 56px 0 96px;
    display: flex;
    flex-direction: column;
}

.forWhomTitle {
    color: var(--primary-white, #FFF);
    max-width: 712px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 160% */
    margin-bottom: 0;
}

.forWhomInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
}

.forWhomLine {
    display: flex;
    gap: 32px;
    align-items: center;
}

.check {
    width: 32px;
    height: 32px;
}

.forWhomText{
    margin: 0;
    color: var(--primary-white, #FFF);
    font-family: var(--secondary-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.currenciesContentWrapper {
    padding: 88px 0 128px;
}

.currenciesContentWrapper .sectionTitle {
  max-width: 1100px;
}

.currenciesBlocksWrapper {
    min-width: 100%;
    display: grid;
    row-gap: 48px;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
}
.btnSigninMobile {
    display: none;
}

@media only screen and (max-device-width: 1024px) {
    .btnSigninMobile {
        display: block;
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 1024px) {
    .heroContentWrapper {
        flex-wrap: wrap;
        justify-content: center;
    }

    .heroInfoWrapper {
        width: auto;
        margin-bottom: 50px;
    }

    .infoWrapper {
        flex-wrap: wrap;
    }

    .infoWrapperColumn {
        margin-bottom: 40px;
    }

    .benefitsContentWrapper {
        padding: 0 16px 50px;
    }
    .currenciesBlocksWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    }

}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 574px) {

    .DropDownList {
        left: 17%;
    }
    .heroTitle {
        font-size: 40px;
        line-height: normal;
        text-align: center;
    }
    .heroContentWrapper {
        padding: 0;
    }
    .subtitle {
        font-size: 24px;
        line-height: normal;
        text-align: center;
    }
    .whyTitle {
        font-size: 16px;
    }
    .sectionTitle {
        margin-bottom: 20px;
        width: 70%;
        font-size: 24px;
        line-height: 40px;
    }
    .infoTitle, .textBlock, .forWhomText {
        font-size: 16px;
        line-height: 24px;
    }
    .infoSubtitle {
        font-size: 14px;
        line-height: 18px;
        margin-top: 8px;
    }
    .lineWrapper {
        font-size: 14px;
    }
    .infoDescriptionWrapper {
        margin-top: 15px;
    }
    .cardItem {
        width: 160px;
        height: 160px;
    }
    .number, .forWhomTitle {
        font-size: 24px;
        line-height: 40px;
    }
    .check {
        width: 24px;
        height: 24px;
    }
    .forWhomLine {
        gap: 16px;
    }
    .sectionTitle {
        width: 100%;
    }
    .currenciesContentWrapper {
        padding: 40px 0;
    }
}
