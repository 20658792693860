.buttonBack {
    width: 192px;
    height: 40px;
    margin: 50px 0;
    border: 1px solid #DF853B;
    border-radius: 22px;
    background: transparent;
    color: #DF853B;
    font-size: 16px;
    cursor: pointer;
}

.buttonBack:hover {
    background-color: #FEE7D5;
}

.imgArrow {
    margin-right: 7px;
    margin-bottom: 4px;
}

.hero {
    width: 100vw;
    position: relative;
    margin: 70px auto 0;
}

.hero::before {
    content: url("../../../assets/img/new/hero_bg_first.svg");
    position: absolute;
    bottom: 200px;
    left: 0;
    z-index: -1;
    width: 401px;
    height: 355px;
}

.hero::after {
    content: url("../../../assets/img/new/hero_bg_second.svg");
    position: absolute;
    bottom: 20%;
    left: 0;
    z-index: -2;
    width: 503px;
    height: 315px;
}

.fragmentOne,
.fragmentTwo,
.fragmentThree,
.fragmentFour {
    position: relative;

}

.fragmentOne::before {
    content: url("../../../assets/img/new/fragment_one.svg");
    position: absolute;
    top: 0;
    left: 200px;
    z-index: -1;
    animation-name: firstAnimation;

    width: 457px;
    height: 455px;
    transform: rotate(210deg);
}

.fragmentTwo::before {
    position: absolute;
    z-index: -1;
    width: 365px;
    height: 442px;
    top: -240px;
    left: 111px;
    content: url("../../../assets/img/new/fragment_two.svg");
    animation-name: secondAnimation;
    display: block;
    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentThree {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentFour {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


@keyframes firstAnimation {
    to {
        transform: rotate(210deg)
    }
    from {
        transform: rotate(-150deg)
    }
}


@keyframes secondAnimation {
    to {
        transform: rotate(0)
    }
    from {
        transform: rotate(-360deg)
    }

}
.wrapper {
    display: flex;
    flex-direction: column;
}
.title {
    margin-bottom: 16px;
    font-size: 52px;
    font-weight: 700;
    line-height: 55px;
    color: #3F3F3F;
}
.subtitle {
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: #3F3F3F;
}
.text {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #797979;
}
.pageWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Cera Pro, sans-serif;
    color: #000;
    overflow: hidden;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1180px;
    color: #3F3F3F;
    font-family: Cera Pro, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    gap: 64px;
    margin: 0 auto 50px;
    padding: 0 16px;
}
.contentWrapper {
    margin: 70px auto 0;
    overflow: auto;
}

.highRisk {
    max-width: 230px;
}

.table {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 37px;
    overflow: auto;
}

.tableHeader {
    background-color: #DF853B;
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerItem, .headerItemWallet {
    width: 25%;
    font-size: 24px;
    font-family: Cera Pro, sans-serif;
    color: white;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    padding: 24px;
    position: relative;
}

.headerItem:before, .headerItemWallet:before  {
    position: absolute;
    content: '';
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 2px;
    height: 70px;
    background-color: #CD7832;
}
.headerItemWallet:before {
    left: 14px;
}
.tableBodyWrapper {
    display: flex;
    flex-direction: column;
    background-color: #F2CEB1;
    padding: 16px 16px 16px 0;
    border-radius: 24px;
    width: 100%;
}

.row {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.row::after {
    position: absolute;
    content: '';
    height: 2px;
    background-color: #F6F6F6;
    left: 299px;
    right: 24px;
    bottom: 0;

}

.siderItem {
    padding: 32px 24px;
    width: 25%;
    font-size: 20px;
    font-family: Cera Pro, sans-serif;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
}


.siderItem::after {
    position: absolute;
    content: '';
    height: 2px;
    background-color: #ECDFD2;
    width: 13%;
    left: 16px;
    bottom: 0;
}

.rowItem {
    width: 25%;
    padding: 32px 18px;
    background-color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.oneItem {
    width: 100%;
    text-align: center;
}

.topLeftBorder {
    border-top-left-radius: 20px;
}

.topRightBorder {
    border-top-right-radius: 20px;
}

.bottomLeftBorder {
    border-bottom-left-radius: 20px;
}

.bottomRightBorder {
    border-bottom-right-radius: 20px;
}

.bottomBorderSider::after {
    content: none;
}

.border {
    position: relative;
}

.border::before {
    position: absolute;
    content: '';
    width: 2px;
    background-color: #F6F6F6;
    left: 0;
    top: 16px;
    bottom: 16px;
}
.payouts {
    display: flex;
    flex-direction: column;
    gap: 32px
}

.siderItem .payouts p {
    text-align: left;
}
.payouts p {
    text-align: center;
}
.settlement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.settlementTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    gap: 41.5px;
}

.settlementTextWrapper span {
    color: #CCCCCC;
}

.shadow {
    box-shadow: -7px 10px 18px 0px rgba(176, 120, 105, 0.1);
}


.shadowTop {
    box-shadow: 4px -1px 18px 0px rgba(176, 120, 105, 0.1);
}


.mobileTableBodyWrapper {
    display: none;
}

.mobileTableItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #3F3F3F;
}

.mobileItemTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}

.mobileItemSubtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.buttonsBlock {
    display: none;
}

.desktopHide {
    display: none;
}

.green {
    color: #71B48D;
}
.red {
    color: #EB5757;
}

@media only screen
and (max-device-width: 1440px) {
    .contentWrapper {
        margin: 50px 0;
        overflow: auto;
        padding: 0 16px;
    }
    .title {
        font-size: 52px;
        font-weight: 700;
    }

}


@media only screen
and (max-device-width: 1111px) {

    .title {
        font-size: 40px;
    }


    .headerItem, .headerItemWallet {
        font-size: 20px;
        line-height: 130%;
        padding: 20px;
        position: relative;
    }

    .rowItem {
        padding: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
    }

    .siderItem {
        padding: 32px 24px;
        font-size: 16px;
        line-height: 130%;
    }
    .buttonBack {
        margin: 30px 0 10px;
    }

}

@media only screen
and (max-device-width: 881px) {

    .table {
        gap: 14px;
    }

    .tableHeader {
        padding-right: 14px;
    }

    .tableBodyWrapper {
        padding: 14px 14px 14px 0;
    }
    .headerItem, .headerItemWallet {
        font-size: 16px;
        padding: 14px;
        position: relative;
    }
    .headerItemWallet {
        padding: 14px 0 14px 30px;
    }
    .headerItem:before {
        left: -3px;
    }
    .rowItem {
        padding: 14px;
        font-size: 12px;
    }

    .siderItem {
        padding: 14px;
        font-size: 16px;
        line-height: 130%;
    }

}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .wrapMobileTitle {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }
    .table {
        overflow: hidden;
    }

    .title {
        margin: 0;
        font-size: 32px;
    }
    .subtitle {
        font-size: 20px;
        line-height: 24px;
    }
    .text {
        font-size: 14px;
        line-height: 18px;
    }
    .tableHeader {
        width: fit-content;
        justify-content: flex-start;
        transition: transform 700ms ease-in;
    }

    .headerItem, .headerItemWallet {
        width: 248px;
        padding: 16px 33px;
    }

    .tableHeader div:first-child {
        display: none;
    }

    .tableHeader div:nth-child(2)::before {
        content: none;
    }


    .tableBodyWrapper {
        display: none;
    }

    .mobileTableBodyWrapper {
        display: flex;
        flex-direction: column;
        background-color: #f6f6f6;
        gap: 56px;
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100vh;
    }


    .mobileTableBodyWrapper::-webkit-scrollbar {
        display: none;
    }


    .mobileTableBodyWrapper {
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 10px;
    }
    .topMobileBorder {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border-top: 10px solid #F2CEB1;
        border-left: 10px solid #F2CEB1;
        border-bottom: 10px solid #F2CEB1;
    }
    .mobileBorder {
        border-top: 10px solid #F2CEB1;
        border-bottom: 10px solid #F2CEB1;
    }
    .bottomMobileBorder {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        border-top: 10px solid #F2CEB1;
        border-right: 10px solid #F2CEB1;
        border-bottom: 10px solid #F2CEB1;
    }
    .payouts {
        align-items: flex-start;
    }

    .hide {
        display: none;
    }

    .buttonsBlock {
        display: flex;
        align-items: center;
        gap: 12px;

    }

    .menuButton {
        position: relative;
        display: flex;
        width: 52px;
        height: 52px;
        justify-content: center;
        align-items: center;
        border-radius: 26px;
        background-color: #DF853B;
        border: none;
        transition: background-color .3s ease-in;
    }

    .menuButton:active {
        background-color: #CD7832;
    }

    .menuButton::after {
        content: url("../../../assets/img/arrowNext.svg");
        width: 24px;
        height: 24px;
    }

    .menuButton:nth-child(2)::after {
        transform: rotate(180deg);
    }

    .unactive {
        opacity: .7;
    }

    .buttonBack {
        margin: 40px 0 0;
    }
}
