.transactionHistoryDesktop {
    width: 100%;
    position: relative;
    padding: 0 44px;
}

.title {
    font-family: 'Cera Pro';
    font-weight: 900;
    font-size: 35px;
    line-height: 44px;
    color: #3F3F3F;
    position: relative;
}


.btnBlock {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 46px;
}

table {
    background-color: #F6F4F2 !important;
    border-spacing: 0 10px !important;
}


.tableRow {
    height: 75px;
    background-color: #FFFFFF;
    border-radius: 20px;
    font-family: 'Cera Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
    margin-top: 10px;
}

thead tr > th,
thead tr > td {
    background: transparent !important;
    border-bottom: none !important;
    font-family: 'Cera Pro', sans-serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    color: #ABABAB !important;
}


thead tr > th::before {
    content: none !important;
}

.custom_pagination {
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    background-color: #F6F4F2 !important;
}

.custom_pagination > li {
    height: 36px !important;
    width: 36px !important;
    line-height: 30px !important;
    border: none !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: transparent !important;
}


.custom_pagination li:hover {
    background-color: #EDEDED !important;
}

.custom_pagination > li > a {
    font-family: 'Cera Pro', sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;;
    color: #ABABAB !important;
}

.custom_pagination > li > button {
    height: 36px !important;
    width: 36px !important;
    line-height: 30px !important;
    border: 1px solid transparent !important;
    border-radius: 6px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: transparent !important;
}

.custom_pagination > li:hover > button > span > svg {
    fill: #DF853B !important;
}

.clicked {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.clicked img {
    margin-left: 5px;
}


.reserve_message {
    height: 250px;
    display: flex;
    align-items: center;
}

.unactive {
    background: #EDEDED;
    color: #ABABAB;
    font-size: 16px;
    text-wrap: nowrap;

}

.active {
    background: #DF853B;
    color: #FFFFFF;
    font-size: 16px;
    text-wrap: nowrap;

}


.noTransactions {
    height: 60vh;
    width: 100%;
    font-family: 'Cera Pro';
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #797979;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.refund {
    width: 45px;
    height: 35px;
    background: #EDEDED;
    border-radius: 7px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}


.disabled {
    opacity: 0.5;
    cursor: pointer;
}

.hidden {
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
}

.refund img {
    width: 20px;
    height: 20px;
}

.refund:hover:after {
    content: 'Refund';
    padding: 5px 12px;
    background-color: #3F3F3F;
    color: #fff;
    position: absolute;
    top: 40px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;;
}

.amount {
    text-align: start;
    padding-right: 70px;
}

.numb {
    padding-left: 56px;
}

.transactionHistoryMobile {
    display: none;
}

.my_input {
    width: 200px;
    padding: 8px 0 10px 8px;
    margin-left: 40px;
    border: none;
    font-family: 'Cera Pro';
    font-size: 14px;
}


.blankHeader {
    display: none;
}


.printData {
    display: none;
}


.mobileCardsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.filterMenuWrapper {
    display: none;
}

.secondLineWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.searchWrapper {
    position: relative;
}

.searchIcon {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 16px;
    top: 14px;
}

.searchWrapper > * > input::-webkit-input-placeholder {
text-align: left;
}

@media only screen
and (min-device-width: 625px)
and (max-device-width: 1256px) {
    .transactionHistoryDesktop {
        padding: 0 16px;
    }
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .transactionHistoryDesktop {
        padding: 0;
    }

    .topMenuWrapper {
        display: flex;
        flex-direction: column;
    }

    .secondLineWrapper {
        flex-direction: column;
    }

    table thead {
        display: none;
    }


    .tableRow {
        display: grid;
        height: fit-content;
        font-size: 18px;
        line-height: 23px;
        color: #3F3F3F;
    }

    thead tr > th,
    thead tr > td {
        background: transparent !important;
        border-bottom: none !important;
        font-family: 'Cera Pro', sans-serif !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 23px !important;
        color: #ABABAB !important;
    }


    .btnBlock {
        gap: 8px;
        margin-top: 28px;
        overflow-x: auto;
    }

    .btnBlock::-webkit-scrollbar {
        display: none;
    }

    .btnBlock {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .filterMenuWrapper {
        display: block;
        margin-top: 24px;
    }

    .line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom_pagination > li > a {
        font-family: 'Cera Pro', sans-serif !important;
        font-weight: 400 !important;
        font-size: 14px !important;;
        color: #ABABAB !important;
        min-width: 40px !important;
    }

    .custom_pagination > li {
        height: 25px !important;
        width: 25px !important;
        line-height: 25px !important;
        padding: 10px;
        margin-inline-end: 0px !important;
    }

    .custom_pagination > li > p{
        font-size: 14px !important;
        min-width: 40px !important;
    }

    .custom_pagination > li > button {
        height: 25px !important;
        width: 25px !important;
        line-height: 25px !important;
    }


}


.statementWrapper {
    position: absolute;
    right: 0;
    top: 73px;
}


@media print {
    .btnBlock,
    .paginator,
    .showData,
    .transactionHistoryMobile,
    .bingo,
    .copyright,
    .statementWrapper {
        display: none;
    }

    table {
        width: 100%;
        background-color: #FFFFFF;
        box-sizing: border-box;
        max-width: 1100px;

    }

    .table_titles tr {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .lastTd {
        display: none;
    }

    .transactionHistoryDesktop {
        padding-top: 100px;
    }

    .blankHeader {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }


    .amount {
        text-align: start;
        padding-right: 0;
        margin: 0;
        position: relative;
        right: 50px;
    }

    .printData {
        display: block;
        width: 100%;
    }

    .status {
        position: relative;
        right: 100px;
    }

    .live {
        width: initial;
        position: relative;
        right: 80px;
    }

    .time {
        text-align: center;
        width: fit-content;

    }

    .numb {
        width: 150px;
        text-align: start;
    }

}