.wrapper {
    max-width: 450px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 70px 40px 40px;
    position: relative;
    box-sizing: border-box;
    max-height: 775px;
    height: fit-content;
    overflow-y: auto;
}

.close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.titleBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-family: 'Cera Pro', sans-serif;
    font-weight: 700;
}


.transactionNumber {
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 18px;
    line-height: 23px;
    color: #DF853B;
}


.title {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
}

.infoBlock {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.infoBlock p:last-child {
    margin-bottom: 0;
}

.infoTitle {
    color: #ABABAB;
    margin: 0 0 10px;
}

.infoSubtitle {
    color: #3F3F3F;
    margin: 0 0 25px;
}

.positive {
     color: #71B48D;
}

.negative {
    color: var(--primary-red);
}

