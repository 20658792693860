.wrapper {
    max-width: 450px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 40px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    font-family: 'Cera Pro',sans-serif;
    font-style: normal;
}


.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    margin-bottom: 25px;
}

.error {
    color: var(--primary-red);
}

.message {
    font-family: 'Cera Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #797979;
    margin-bottom: 60px;
}

.buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .wrapper {
        max-width: 300px;
        padding: 20px;
    }

}


