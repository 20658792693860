.layout {
    background-color: #F6F4F2;
    padding: 0 44px;
}

.accounts_list_wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    position: relative;
    background-color: #F6F4F2;
}


.infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 30px;
    margin-bottom: 30px;
}


@media only screen and (max-width: 768px) {

    .layout {
        padding: 0;
    }


    .accounts_list_wrapper {
        width: 100%;
        margin: 0;
        padding: 28px 0 36px;
        flex-direction: column;
        gap: 16px;

    }


    .acc_block {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 16px 10px;
        margin-right: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .acc_number {
        font-size: 11px;
    }

    .right {
        min-width: 95px ;
    }

    .acc_number {
        font-size: 11px;
    }

    .credits {
        font-size: 11px;
    }
}

@media print {
    .accounts_list_wrapper
    {
        display: none;
    }
}



