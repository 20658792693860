.customTab {
    font-family: "Cera Pro", sans-serif;
    display: flex;
    align-items: center;
    text-wrap: none;
    height: 40px;
    min-width: fit-content;
    justify-content: center;
    padding: 10px 20px;
    color: #3F3F3F;
    border-radius: 22px;
    background-color: #EDEDED;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: all .3s ease-in-out;
    box-sizing: border-box;
    border: 1px solid  #CCC;
}

.customTab:hover {
    border-radius: 22px;
    border: 1px solid  #CCC;
    background-color:  #D9D9D9;
}

.active {
    border-radius: 22px;
    background-color: #3F3F3F;
    border: 1px solid  #3F3F3F;
    color: #FFFFFF;
}


.active:hover {
    background-color: #3F3F3F;
    border: 1px solid  #3F3F3F;
}

.formButton {
    padding: 8px 40px;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .MyBtn {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 20px;
    }

    .popup {
        padding: 10px 51px;
        width: auto;
    }

    .padding {
        padding: 10px 30px;
        width: 100%;
    }
    .formButton {
        padding: 8px 30px;
    }
}
