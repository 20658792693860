.DropDownWrapper {
    text-align: center;
    position: relative;
}

.DropDownList {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 160px;
    padding-top: 5px;
    margin-top: 10px;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;
}
.NoneDropDown {
    display: none;
}
.DropDownListItem {
    width: 150px;
    margin: 0 auto 5px;
    padding: 7px 5px;
    border-radius: 20px;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.DropDownListItem:hover, .activeItem:hover {
    background: #FEE7D5;
}
.activeItem {
    color: #EB6D35;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.Link {
    display: block;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.Link:hover {
    color: #ABABAB;
    cursor: pointer;
}
.MobileLink {
    display: none;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 1024px) {
    .DropDownList {
        right: -50px;
    }
    .Link {
        display: none;
        color: #ABABAB;
        font-size: 16px;
        line-height: 17px;
    }
    .MobileLink {
        display: block;
    }
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 575px) {
.DropDownList {
    right: -70%;
}
}
