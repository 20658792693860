.wrapper {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--bingopay-font);
}

.address {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

}

.dynamicAddresses {
}

.dynamicAddressItem {
    margin: 25px 0 25px 0;
}

.addressLabel {
    color: #797979;
    margin-left: 15px;
    margin-bottom: -15px;
}

.addressValue {
    font-size: 14px;
    line-height: 130%;
    color: #3F3F3F;
}

.copyIcon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 3px;
    color: black;
}

.formWrapper {
    margin-top: 20px;
}


.formWrapper label {
    margin-left: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #797979;
}

.formItem {
    margin: 30px 0 30px 0;
}

.inputWrapper {
    display: flex;
    height: 50px;
    justify-content: space-between;
    gap: 30px;
}

.input {
    background: #FFFFFF;
    border-radius: 32px;
    border: 1px solid #D9D9D9;
    min-width: 400px;
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    font-family: var(--secondary-font) ;
    color: var(--primary-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
}


.inputButton {
    padding: 0;
    width: 100px;
    border: 0;
    border-radius: 30px;
    font-family: var(--bingopay-font);
    cursor: pointer;
    text-align: center;
}

.inputAddressButton {
    font-family: var(--bingopay-font);
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 5px 15px 5px 15px;
    margin-left: 20px;

    border-radius: 20px;

    border: none;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    text-wrap: none;
    transition: all .3s ease-in-out;
}

.buttonActive {
    background: #DF853B;
    color: #F6F6F6;
}

.buttonDisabled {
    background-color: #ededed;
    color: #cccccc;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .wrapper {
        max-width: 768px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: var(--bingopay-font);
    }


    .input {
        height: 50px;
        width: 100%;
    }

    .inputButton {
        margin-top: 5px;
        height: 50px;
        width: 100%;
    }

    .inputAddressButton {
        margin-top: 15px;
        width: 100%;
        height: 50px;
        justify-content: center;
    }


    .inputWrapper {
        height: 100px;
        display: block;
    }

    .address {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

}
