.layout {
    width: 95%;
    margin: 0 auto;
}

.walletsWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;;
    flex-wrap: wrap;
    gap: 30px;
    /*background-color: #F6F4F2;*/
}

.preTableWidgets {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 40px 0 40px 0;
}

.txsTitle {
    font-family: var(--bingopay-font);
    color:  var(--primary-black);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

.txsCount {
    color: var(--gray-dark);
    font-family: var(--bingopay-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 15px;
}

.searchWrapper {
    max-width: 343px;
    padding-right: 20px;
    z-index: 0;
}

.tableSwitcher {
    display: flex;
    padding: 0;
    gap: 16px;
}

@media only screen and (max-width: 768px) {

    .walletsWrapper {
        width: 100%;
        margin: 0;
        padding: 20px 0;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }


    .acc_block {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 16px 10px;
        margin-right: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .acc_number {
        font-size: 11px;
    }

    .right {
        min-width: 95px ;
    }

    .acc_number {
        font-size: 11px;
    }

    .credits {
        font-size: 11px;
    }
}

@media print {
    .walletsWrapper
    {
        display: none;
    }
}



