.wrapper {
    max-width: 450px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 70px 40px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    font-family: 'Cera Pro', sans-serif;
    font-style: normal;
}


.content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    text-transform: uppercase;
}

.addressLine {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 100%;
}

.address {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.icon path{
    fill: #DF853B;
}
.inactiveIcon path {
    fill: #3f3f3f;
    cursor: pointer;
}
.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.icon path,
.closeIcon path {
    transition: fill .3s ease;
}

.icon:hover path,
.closeIcon:hover path {
    fill: #DF853B;
}

.qr {
    width: 248px;
    height: 248px;
}
.infoBlock {
    display: flex;
    gap: 8px;
    padding-right: 18px;
    margin: 20px 0;
}
.coinIcon {
    width: 32px;
    height: 32px;
    margin-right: 7px;
}
.currency {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}
.fullCurrency {
    color: #ABABAB;
}
.plate {
    padding: 4px 8px;
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #EDEDED;
    background-color: #F6F6F6;
    color: #CD7832;
}
.plateNon {
    display: none;
}
.warningIcon {
    width: 24px;
    height: 24px;
    margin-right: 7px;
}
.warningIcon path {
    fill: #EB5757;
}
.warningText {
    font-size: 16px;
    color: #EB5757;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .wrapper {
        max-width: 288px;
        border-radius: 20px;
        padding: 52px 16px 24px;
    }

    .content {
        gap: 16px;
    }

    .closeIcon {
        top: 16px;
        right: 16px;
    }

    .title {
        font-size: 20px;
    }

    .address {
        font-size: 14px;
    }

    .qr {
        width: 180px;
        height: 180px;
    }

    .icon {
       width: 16px;
        height: 16px;
    }

}


