.container {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: 100%;
    position: relative;
}

.title {
    color: var(--primary-orange, #DF853B);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 150% */

}

.labelTitle {
    color: var(--gray-ultradark, #797979);
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 4px;
}

.buttonsBlock {
    display: flex;
    gap: 24px;
    margin: 56px 0 32px;
}
.firstFormWrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
    "name name"
    "email  mobilePhone"
    "message  message"
    "submit submit";
    row-gap: 24px;
    column-gap: 16px;
}
.secondFormWrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
    "name name"
    "email  mobilePhone"
    "company  website"
    "submit submit";
    row-gap: 24px;
    column-gap: 16px;
}

.message {
    grid-area: message;
}

.message textarea {
    outline: none;
    min-width: 288px;
    max-width: 870px;
    min-height: 155px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    font-family: var(--secondary-font) ;
    color: var(--primary-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 17px 25px;
    box-sizing: border-box;
    resize: none;
    transition: all .3s;
    border-radius: 13px;
    border: 1px solid var(--gray-medium, #D9D9D9);
    background: var(--primary-white, #FFF);
}

.message textarea:focus {
    border: 1px solid var(--primary-orange) !important;
}

.message textarea:hover {
    border: 1px solid var(--orange-medium);
}

.message textarea::placeholder {
    color: #C5C5C5;
}

.website {
    grid-area: website;
    position: relative;
}

.mobilePhone {
    grid-area: mobilePhone;
    position: relative;
}

.email {
    grid-area: email;
    position: relative;
}

.company {
    grid-area: company;
    position: relative;
}

.name {
    grid-area: name;
    position: relative;
}

.submitButton {
    margin-top: 30px;
    grid-area: submit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error {
    position: absolute;
    left: 25px;
    font-family: 'Cera Pro', sans-serif;
    top: 80px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-red);
}

.successMessage {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    text-transform: uppercase;
    color: #71B48D;
}

.fail {
    color: var(--primary-red);
}

@media only screen and (max-width: 890px) {

    .formWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .message textarea {
        max-width: 870px;
        min-height: 155px;
        min-width: 350px;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: none;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
        font-family: 'Cera Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 17px 25px;
        box-sizing: border-box;
        resize: none;
    }
}

@media only screen and (max-width: 640px) and (min-width: 320px) {

    .container {
        padding: 10px 0;
    }

    .title {
        font-size: 24px;
        line-height: 34px;
        text-align: left;
        width: 100%;
        padding-left: 0;
        margin-bottom: 0;
    }
    .buttonsBlock {
        gap: 14px;
        margin: 30px 0;
    }

    .firstFormWrapper, .secondFormWrapper {
        display: flex;
        flex-direction: column;
    }

    .submitButton {
        margin-top: 0;
        width: 100%;
    }

    .submitButton > div {
        width: 100%;
    }

}


