.loginContainer {
    display: flex;
    flex-direction: row-reverse;
    height: 100vh;
    font-family: 'Cera Pro', sans-serif;
    width: 100%;
    overflow: hidden;
}

.rightSide {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.leftSide {
    width: 50%;
    position: relative;
    background: #DF853B;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 50px;
}
.ellipseUp {
    position: absolute;
    right: 0;
    top: 0;
}
.ellipseDown {
    position: absolute;
    bottom: 0;
    right: 0;
}
.mainContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1280px;
    height: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/img/new/login_frame.svg");
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}
.header {
    margin-bottom: 50px;
}

.logoTitle {
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    cursor: pointer;
}

.formBlock {
    margin-bottom: 35px;
}

.formTitle {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
}

.loginTitle {
    font-weight: 900;
    font-size: 64px;
    line-height: 96px;
    color: #DF853B;
    text-align: center;
}
.loginSubTitle {
    margin-bottom: 53px;
    font-weight: 500;
    font-size: 32px;
    color: #3F3F3F;
    text-align: center;
}
.lng {
    position: absolute;
    right: 4%;
    top: 7%;
}
.rightContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginBlock {
    min-width: 370px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}


.back {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #F6F6F6;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
}

.back:after {
    content: "";
    display: block;
    position: absolute;
    background: no-repeat url("../../assets/img/arrowBack.png");
    width: 30px;
    height: 20px;
    top: 5px;
    left: 76px;
}

.textBlockTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #F6F6F6;
    margin-bottom: 35px;
}

.textBlockDesc {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #F6F6F6;
    margin-bottom: 25px;
}

.error {
    margin-top: 20px;
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: var(--primary-red);
}

.backMobile {
    display: none;
}

.logoMobile {
    display: none;
}

.reservedBlock {
    display: none;
}

.headerBlock {
    display: flex;
    align-items: center;
}
@media only screen

and (max-device-width: 1440px) {
    .mainContent {
        width: 950px;
        height: 600px;
        padding: 0 40px;
    }
    .loginTitle {
        font-size: 52px;
    }
    .leftSide {
        padding-bottom: 0;
    }
    .formBlock {
        margin-bottom: 20px;
    }
    .loginSubTitle {
        margin-bottom: 15px;
    }
    .header {
        margin-bottom: 15px;
    }
}

@media only screen

and (max-device-width: 1120px) {
    .loginBlock {
        width: 100%;
        padding: 0 0 0 25px;
    }
    .mainContent {
        width: 750px;
        height: 500px;
        padding: 0 20px;
    }
    .loginTitle {
        font-size: 42px;
        line-height: normal;
    }
    .loginSubTitle {
        font-size: 25px;
    }
    .formBlock {
        margin-bottom: 10px;
    }
    .loginSubTitle {
        margin-bottom: 5px;
    }
    .header {
        margin-bottom: 5px;
    }
    .error {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .logoTitle {
        font-weight: 600;
        font-size: 18px;
    }
    .logo {
        width: 18px;
    }
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 680px) {
    .rightSide, .leftSide, .textBlock {
        display: none;
    }
    .mainContent {
        width: 90vw;
        height: 100vh;
        background-position: center;
        background-image: url("../../assets/img/new/login_frame_mobile.svg");
    }

    .loginBlock {
        padding: 0;
        min-width: 300px;
    }

    .loginTitle, .loginSubTitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        color: #F6F4F2;
        text-align: start;
    }
    .loginSubTitle {
        margin-bottom: 40px;
    }
    .logoTitle {
        display: none;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        margin-left: 7px;
    }

    .logoDesktop {
        display: none;
    }

    .logoMobile {
        display: block;
    }
    .lng {
        right: 12%;
        top: 20px;
    }
    .header {
        position: absolute;
        top: 10px;
        left: 0;
    }

    .headerBlock {
        display: flex;
        align-items: center;
    }
    .formTitle {
        color: #EDEDED;
    }
    .reserved {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #CCCCCC;
    }

    .reservedBlock {
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .buttonWrapper {
        width: 100%;
    }

    .button {
        width: 100%;
    }
}
