.transactionHistoryDesktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Cera Pro', sans-serif;
}

.title {
    font-weight: 900;
    font-size: 35px;
    line-height: 44px;
    color: #3F3F3F;
    position: relative;
}


.btnBlock {
    display: flex;
    gap: 20px;
    margin-top: 46px;
}

table {

    border-collapse: separate;
    border-spacing: 0 10px;
}

tbody {
    border-radius: 0 0 24px 24px;
}

td {
    padding: 5px;
    background-color: white;
}

td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

td:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

tbody tr {
    height: 75px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
}

th {
    padding: 10px 0 10px 5px;
    font-size: 16px;
    line-height: 15px;
    text-align: left;
}

.table {
    width: 100%;
    box-sizing: border-box;
    border-radius: 24px;
    margin-top: 72px;

}


.custom_pagination {
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
}

.custom_pagination > li {
    height: 36px !important;
    width: 36px !important;
    line-height: 30px !important;
    border: none !important;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: transparent !important;
}


.custom_pagination li:hover {
    background-color: #EDEDED !important;
}

.custom_pagination > li > a {
    font-family: 'Cera Pro', sans-serif !important;
    font-weight: 500 !important;
    font-size: 18px !important;;
    color: #ABABAB !important;
}

.custom_pagination > li > button {
    height: 36px !important;
    width: 36px !important;
    line-height: 30px !important;
    border: 1px solid transparent !important;
    border-radius: 6px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: transparent !important;
}

.custom_pagination > li:hover > button > span > svg {
    fill: #DF853B !important;
}

.clicked {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.clicked img {
    margin-left: 5px;
}

.table_titles {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    color: #ABABAB;
    text-transform: capitalize;
}

.position {
    display: flex;
    justify-content: space-around;
}

.values {
    color: #000;
    font-size: 16px;
    line-height: 16px;
    text-align: start;
    padding-right: 70px;
}

.positive {
    color: #71B48D;
}

.negative {
    color: var(--primary-red);
}

.values:last-child {
    padding-right: 20px;
}

.string {
    display: flex;
    border-top: 1px solid #5E5E5E;
}

.canceled {
    color: #D15863;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    padding: 0 20px;
}

.confirmed {
    color: #41C890;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    padding: 0 20px;
}
.paymentId {
    max-width: 200px;
}
.amountField {
    min-width: 140px;
}
.dateColumn {
    min-width: 140px;
}

.paginator {
    display: flex;
    justify-content: center;
}

.reserve_message {
    height: 250px;
    display: flex;
    align-items: center;
}

.unactive {
    background: #EDEDED;
    color: #ABABAB;
    font-size: 16px;
}

.active {
    background: #DF853B;
    color: #F6F6F6;
    font-size: 16px;
}

.img {
    display: flex;
    justify-content: flex-end;

}


.img img {
    width: 40px;
    height: 40px;
}

.img:hover:after {
    content: 'View details';
    padding: 5px 12px;
    background-color: #3F3F3F;
    color: #fff;
    position: absolute;
    top: 70px;
    border-radius: 5px;
    left: 0;
    z-index: 20;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.img:hover{
    cursor: url('../../../../assets/img/view_details_cursor.svg'), auto;
}

.hide {
    display: none;
}

.amount {
    text-align: start;
    padding-right: 70px;
}

.numb {
    padding-left: 56px;
}


.transactionHistoryMobile {
    display: none;
}


.detailsMobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: flex-start;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {


    .transactionHistoryDesktop {
        display: none;
    }

    .transactionHistoryMobile {
        display: block;
        margin: 26px 0;
        max-width: 100vw;
    }

    .title {
        font-weight: 900;
        font-size: 20px;
        line-height: 25px;
    }

    .unactive {
        background: #EDEDED;
        color: #ABABAB;
        font-size: 16px;
    }

    .active {
        background: #DF853B;
        color: #F6F6F6;
        font-size: 16px;
    }

    .btnBlock {
        width: auto;
        gap: 8px;
        overflow-x: scroll;
        margin-top: 16px;
    }

    .transactionsList {
        margin-top: 24px;
    }

    .transactionBlock {
        width: auto;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 12px;
        padding: 16px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .rowBlock {
        display: flex;
        flex-direction: column;
    }

    .valueName {
        color: #ABABAB;
        margin-right: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .valuePayload {
        color: #3F3F3F;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .transactionRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .aqTransactions {
        width: 100px !important;
        max-width: 100px !important;
    }

    .custom_pagination > li > a {
        font-family: 'Cera Pro', sans-serif !important;
        font-weight: 400 !important;
        font-size: 14px !important;;
        color: #ABABAB !important;
        min-width: 40px !important;
    }

    .custom_pagination > li {
        height: 25px !important;
        width: 25px !important;
        line-height: 25px !important;
        padding: 10px;
        margin-inline-end: 0px !important;
    }

    .custom_pagination > li > p{
        font-size: 14px !important;
        min-width: 40px !important;
    }

    .custom_pagination > li > button {
        height: 25px !important;
        width: 25px !important;
        line-height: 25px !important;
    }



}

.showData {
    display: none;
}

.blankHeader {
    display: none;
}

@media print {
    .transactionHistoryMobile,
    .paginator,
    .bingo,
    .copyright,
    .hideData {
        display: none;
    }

    .table_titles {
        width: 100%;
    }
    .table_titles img {
        display: none;
    }

    .table_titles tr {
        display: flex;
        justify-content: space-between;
    }

    .blankHeader {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
    }

    .numb {
        padding: 0;
        margin: 0;

    }

    .type {
        transform: translateX(-120px);
    }

    .showData {
        display: block;
    }

    .sum {
        width: 160px;
    }

    .transactionsHead {
        display: none;
    }
}
