.button {
    font-family: var(--primary-font);
    display: flex;
    flex-direction: row;
    min-width: 64px;
    height: 32px;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
    padding: 7px 16px;
    background-color: #DF853B;
    border-radius: 32px;
    color: #F6F6F6;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-wrap: none;
    transition: all .3s ease-in-out;
}

.button:hover {
    background-color: #CD7832;
}

.primaryBlack {
    background-color: #3f3f3f;
}

.primaryBlack:hover {
    background-color: #797979;
}

.medium {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    gap: 8px;
    height: 40px;
}

.large {
    padding: 16px 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    height: 52px;
    background-color: #3F3F3F;
}

.hero {
    border-radius: 54px;
    background: linear-gradient(110deg, #F2798A 0%, #F38C68 30.82%, #F7C10B 80.90%, #FBCA04 100%);
    padding: 18px 40px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    height: 68px;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .large {
        width: 300px;
    }
    .hero {
        width: 100%;
        height: 40px;
        font-size: 16px;
        margin-bottom: 10px;
    }
    /*.button {*/
    /*    padding: 8px 20px;*/
    /*    font-size: 14px;*/
    /*    line-height: 18px;*/
    /*    height: 48px;*/
    /*    width: 100%;*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*}*/

    /*.button {*/
    /*    padding: 10px 51px;*/
    /*    width: auto;*/
    /*    height: auto;*/
    /*}*/
}
