.wrap {
    position: relative;
    margin: 50px;
}
.headerWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name {
    max-width: 238px;
}
.chooseBank {
    display: flex;
    align-items: center;
}
.icon, .iconLoader {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px;
    background-image: url("../../../../assets/img/new/choose-bank-icon.svg");
}
.loader {
    position: absolute;
    top: 0;
    left: -10px;
    right: 0;
    bottom: 0;
    width: 105%;
    height: 200vh;
    z-index: 10;
    background: rgb(246, 244, 242);
}
.iconLoader {
    background-image: url("../../../../assets/img/new/card-icon.svg");
}
.title {
    font-size: 24px;
    font-weight: 700;
    color: #3F3F3F;
}
.cardsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 50px 0;
    padding: 0;
}
.bankCard {
    position: relative;
    width: 400px;
    height: 320px;
    padding: 0;
    margin-right: 30px;
    background: none;
    color: #3f3f3f;
    margin-bottom: 30px;
    border-radius: 0 !important;
}
.numberCard {
    position: absolute;
    left: 10%;
    top: 55%;
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
}
.logoLoader {
    width: 400px;
    height: 400px;
    margin: 100px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/img/logo.svg");
}
.form {
    display: flex;
    flex-direction: column;
    width: 52%;
    padding: 50px;
    margin: 100px auto;
    background-color: #ffffff;
    gap: 40px;
    border-radius: 32px;
}
.formTitle {
    color: #3f3f3f;
}
.wrapBtns {
    display: flex;
    justify-content: center;
}
.contentStyle  {
    width: 65px;
    margin: 0 auto;
}
.contentStyle > ul > li {
    font-weight: 700 !important;
}
.contentStyle > ul > li:hover {
    background-color: #FEE7D5 !important;
}

.open {
    transform: rotate(180deg);
    transition: all .3s ease-in;
}
.dropdownIcon {
    width: 8px;
    height: 4px;
    transition: all .3s ease-in;
}
.amountWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.replenishmentWrap {
    display: flex;
    justify-content: center;
    margin: 100px auto;
    border-radius: 32px;
}
.replenishmentImg {
    width: 500px;
    height: 610px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../assets/img/new/replenishment.svg");
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}
.replenishmentContent {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    width: 520px;
    padding: 30px;
    background-color: #ffffff;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
}
.countDown {
    margin: 20px auto;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: #000000;
}
.replenishmentText, .replenishmentText2, .replenishmentText3, .replenishmentText4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: #3F3F3F;
}
.replenishmentText2 {
    color: #ABABAB;
}
.replenishmentText3 {
    display: flex;
    justify-content: space-between;
}
.replenishmentText4 {
    font-size: 16px;
}
.fieldNumberCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    margin: 30px 0;
    padding: 0 10px;
    background: #F6F4F2;
    border-radius: 8px;
}
.copyWrap {
    display: flex;
    align-items: center;
}
.copyText, .copyTextActive {
    font-size: 14px;
}
.copyTextActive {
    color: #41C890;
}
.copyIcon, .inactiveIcon {
    margin-right: 7px;
    width: 24px;
    height: 24px;
}
.copyIcon path {
    fill: #41C890;
}
.inactiveIcon path {
    fill: #ABABAB;
}
.amount {
    color: #DF853B;
}
.warnings {
    margin: 30px 0;
}
.warningIcon {
    margin-right: 20px;
    width: 24px;
    height: 24px;
}
.responseLogo {
    width: 100%;
    margin: 100px auto;
}
.responseText, .responseText2 {
    position: absolute;
    bottom: 35%;
    left: 43%;
    text-align: center;
}
.responseText {
    left: 34%;
}
.error {
    margin-top: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: var(--primary-red);
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1500px) {
    .responseText {
        left: 27%;
    }
    .form {
        width: 70%;
    }
}

@media only screen and (min-device-width: 620px) and (max-device-width: 1024px) {
    .responseLogo {
        margin: 30px auto;
    }
    .wrapHeader {
        max-width: 250px;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .responseText, .responseText2 {
        bottom: 30%;
        font-size: 14px;
    }
    .responseText {
        left: 31%;
    }
    .name {
        font-size: 16px;
    }
    .form {
        width: 100%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 620px) {

    .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 10px;
    }
    .headerWrap {
        flex-direction: column;
    }
    .chooseBank {
        margin-bottom: 15px;
    }
    .name {
        max-width: 100%;
        font-size: 16px;
    }
    .cardsList {
        margin: 20px 0;
    }
    .bankCard {
        width: 100%;
        height: 100%;
        margin-right: 0;
    }
    .logoLoader {
        width: 200px;
        height: 200px;
    }
    .loader {
        width: 110%;
        height: 250vh;
    }
    .form {
        width: 100%;
        margin: 40px auto;
        padding: 30px 20px;
    }
    .formTitle {
        font-size: 16px;
    }
    .wrapBtns {
        width: 100%;
        justify-content: space-between;
    }
    .title {
        font-size: 18px;
    }
    .error {
        font-size: 14px;
        line-height: normal;
    }
    .replenishmentWrap {
        margin: 30px auto;
    }
    .replenishmentImg {
        display: none;
    }
    .replenishmentText, .replenishmentText2, .replenishmentText3, .replenishmentText4 {
        line-height: normal;
    }
    .replenishmentContent {
        width: 100%;
        padding: 20px;
    }
    .responseLogo {
        margin: 20px auto;
    }
    .responseText {
        left: 5%;
        bottom: 22%;
    }
    .responseText2 {
        left: 30%;
    }
}
