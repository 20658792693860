.buttonBack {
    width: 192px;
    height: 40px;
    margin: 50px 0;
    border: 1px solid #DF853B;
    border-radius: 22px;
    background: transparent;
    color: #DF853B;
    font-size: 16px;
    cursor: pointer;
}

.buttonBack:hover {
    background-color: #FEE7D5;
}

.imgArrow {
    margin-right: 7px;
    margin-bottom: 4px;
}

.hero {
    position: relative;
    width: 100vw;
    margin: 70px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero::before {
    content: url("../../assets/img/new/hero_bg_first.svg");
    position: absolute;
    bottom: 200px;
    left: 0;
    z-index: -1;
    width: 401px;
    height: 355px;
}

.hero::after {
    content: url("../../assets/img/new/hero_bg_second.svg");
    position: absolute;
    bottom: 20%;
    left: 0;
    z-index: -2;
    width: 503px;
    height: 315px;
}

.fragmentOne,
.fragmentTwo,
.fragmentThree,
.fragmentFour {
    position: relative;

}

.fragmentOne::before {
    content: url("../../assets/img/new/fragment_one.svg");
    position: absolute;
    top: 0;
    left: 200px;
    z-index: -1;
    animation-name: firstAnimation;

    width: 457px;
    height: 455px;
    transform: rotate(210deg);
}

.fragmentTwo::before {
    position: absolute;
    z-index: -1;
    width: 365px;
    height: 442px;
    top: -240px;
    left: 111px;
    content: url("../../assets/img/new/fragment_two.svg");
    animation-name: secondAnimation;
    display: block;
    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentThree {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.fragmentFour {
    position: absolute;
    z-index: -1;
    animation-name: firstAnimation;
    display: block;
    animation-duration: 250s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


@keyframes firstAnimation {
    to {
        transform: rotate(210deg)
    }
    from {
        transform: rotate(-150deg)
    }
}


@keyframes secondAnimation {
    to {
        transform: rotate(0)
    }
    from {
        transform: rotate(-360deg)
    }

}

.wrapper {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1180px;
    color: #797979;
    font-family: Cera Pro, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    gap: 64px;
    margin: 0 auto 50px;
    padding: 0 16px;
}

.title {
    font-size: 52px;
    font-weight: 700;
    line-height: 55px;
    color: #3F3F3F;
    margin-bottom: 16px;
}

.content {
    display: flex;
    flex-direction: column;
}

.content_title {
    font-size: 24px;
    text-transform: uppercase;
    color: #3F3F3F;
    font-weight: 700;
}

.titleCard {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: #DF853B;
    text-transform: uppercase;
}
.wrapMethods {
    display: flex;
    justify-content: space-evenly;
}
.wrapCardsTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 180px;
}
.containerMethod {
    display: flex;
    flex-direction: column;
    width: 355px;
    min-height: 600px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, #F2CEB1, #F6F4F2),
        linear-gradient(190.34deg, #F6F4F2 0%, rgba(246, 244, 242, 0.960599) 14.04%, rgba(246, 244, 242, 0) 100%);
    border: 15px solid #F2CEB1;
    border-radius: 28px;
}
.containerMethod:hover {
    background: linear-gradient(0deg, #dc8b49, #F6F5F3), linear-gradient(0, #F6F4F2 0%, rgba(246, 244, 242, 0.960599) 14.04%, rgb(241 238 235 / 0%) 101%);
    border: 15px solid #DF853B;
}
li:first-child {
    border-radius: 28px;
}
li:last-child {
    border-radius: 28px;
}
.description {
    font-size: 20px;
    font-width: 400;
    line-height: 24px;
    color: #797979;
}
.cardSubtitle {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #3F3F3F;
}
.cardText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #797979;
}
@media only screen
and (min-device-width: 643px)
and (max-device-width: 1024px) {
    .wrapMethods {
        flex-wrap: wrap;
    }
    .container {
        margin: 50px auto;
    }
    .buttonBack {
        margin: 20px 0;
    }
    .hero {
        margin: 20px auto 0;
    }
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    body::before {
        top: 0;
    }

    body::after {
        top: 600px;
    }

    .wrapMethods {
        flex-wrap: wrap;
    }
    .title {
        margin-bottom: 0;
        font-size: 32px;
        line-height: 40px;
    }

    .hero {
        margin: 0 auto;
    }
    .content_title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 0;
    }

    .content {
        gap: 14px;
    }
    .container {
        padding: 0 16px;
        max-width: 1180px;
        margin-bottom: 72px;
        margin-top: 80px;
        color: #797979;
        font-family: Cera Pro, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        gap: 40px;
    }

    .bgTwo {
        width: 100%;
    }

    .bgThree {
        width: 100%;
    }
    .buttonBack {
        width: 140px;
        margin: 0;
    }

}
