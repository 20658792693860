.wrapper {
    max-width: 450px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 70px 40px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    font-family: 'Cera Pro', sans-serif;
    font-style: normal;

}


.content {
    display: flex;
    gap: 25px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    text-transform: uppercase;
}

.subTitle {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #DF853B;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.inputLabel {
    color: #3F3F3F;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.inputLabelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.labelButton {
    color: #DF853B;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.lineWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.lineLabel {
    color: #ABABAB;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.lineValue {
    color: #3F3F3F;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.totalValue {
    color: #3F3F3F;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.infoBlock {
    display: flex;
    gap: 8px;
    padding-right: 18px;
    margin: 20px 0;
}
.coinIcon {
    width: 32px;
    height: 32px;
    margin-right: 7px;
}
.currency {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}
.fullCurrency {
    color: #ABABAB;
}
.plate {
    padding: 4px 8px;
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #EDEDED;
    background-color: #F6F6F6;
    color: #CD7832;
}
.plateNon {
    display: none;
}

.icon {
    cursor: pointer;
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.icon path,
.closeIcon path {
    transition: fill .3s ease;
}

.icon:hover path,
.closeIcon:hover path {
    fill: #DF853B;
}


input::placeholder {
    text-align: right;
    color: #C5C5C5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

:-ms-input-placeholder {
    text-align: right; /* for IE 10-11 */
}

::-webkit-input-placeholder {
    text-align: right; /* for IE Edge */
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .wrapper {
        max-width: 288px;
        padding: 52px 16px 24px;
    }

    .closeIcon {
        top: 16px;
        right: 16px;
    }

    .title {
        font-size: 20px;
        line-height: 30px;
    }

    .subTitle {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;

    }
    .labelButton {
        font-size: 14px;
    }

    .lineLabel {
        font-size: 14px;
    }

    .lineValue {
        font-size: 14px;
    }

    .totalValue {
        font-size: 20px;
    }

    .inputLabel {
        font-size: 14px;
    }

    .lineWrapper {
        gap: 0;
    }

    .buttonWrapper {
        width: 100%;
        justify-content: center;
    }
}


