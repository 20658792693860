.MyBtn {
    font-family: "Cera Pro", sans-serif;
    display: flex;
    flex-direction: row;
    width: 222px;
    height: 50px;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background: #DF853B;
    border-radius: 32px;
    color: #F6F6F6;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-wrap: none;
    transition: all .3s ease-in-out;
}

.secondaryBtn {
    width: initial;
    padding: 10px 30px;
    height: fit-content;
}

.download {
    height: 38px;
    padding: 10px 45px 10px 10px;
    width: auto;
    background-image: url("../../assets/img/download.png");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position-x: 88%;
    background-position-y: center;

}

.download:hover {
    cursor: pointer;
    background-color: initial;
    background-image: url("../../assets/img/download.png");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position-x: 88%;
    background-position-y: center;
}

.popupButton {
    padding: 15px 71px;
    width: 175px;
}

.outline {
    width: 100%;
    padding: 15px 119px;
    border: 1px solid #3F3F3F;
    background-color: transparent;
    color: #3F3F3F;
}


.MyBtn:hover {
    background-color: #CD7832;
}


.disabled {
    background-color: #3F3F3F;

}

@media only screen
and (min-device-width: 623px)
and (max-device-width: 1024px) {
    .MyBtn {
        width: 190px;
        height: 50px;
        margin-top: 20px;
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .MyBtn {
        font-size: 16px;
        line-height: 18px;
        height: 40px;
        width: 100% !important;
        margin: auto;
        border: 1px solid #DF853B;
        color: #FFFFFF;
    }

    .popupButton {
        padding: 10px 51px;
        width: auto;
        height: auto;
    }
}
