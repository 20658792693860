.logoBlock {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 46px;
    margin-bottom: 82px;
}

.logo {
    color: #3F3F3F;
    font-family: 'Cera Pro', sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.menuItem {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #ABABAB;
    background-color: transparent;
    display: flex;
    gap: 14px;
}


.menuItem:active {
    background-color: #ABABAB !important;
}


.menuItem > span {
    white-space: normal;
    height: auto;
    line-height: 23px;
}


.menuItem.activeMenuItem:hover > span {
    color: black !important;
}

.menuItem:hover > span {
    color: #ABABAB !important;
}

.menuItem.activeMenuItem > *,
.menuItem.activeMenuItem {
    background-color: transparent;
    color: #3F3F3F;
}


.horMenuItem {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #ABABAB;
    background-color: transparent;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Cera Pro', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ABABAB;
    padding: 0 0 16px 46px;
    width: 100%;
    cursor: default;
    position: absolute;
    bottom: 0;
}

.copyright {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.copyright * {
    width: 100%;
}



@media only screen and (max-width: 768px) {
    .link_wrapper {
        width: auto;
        margin-left: 20px;
    }

    .normal {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        margin: 0 30px 0 0;
        color: #ABABAB;
        padding: 0;
        text-decoration: none;
    }

    .active {
        color: #DF853B;
        border-bottom: 2px solid #DF853B;
    }
}


@media print {
    .link_wrapper {
        display: none;
    }
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 1024px) {



    .menuItem {
        font-size: 16px;
        line-height: 20px;
        gap: 10px;
    }

    .menuItem:active {
        background-color: transparent !important;
    }


    .menuItem.activeMenuItem:hover > span {
        color: #F6F6F6 !important;
    }

    .menuItem.activeMenuItem > *,
    .menuItem.activeMenuItem {
        background-color: transparent;
        color: #F6F6F6;
    }

    .logoBlock {
        display: none;
    }

    .infoWrapper {
        padding: 0 0 16px 24px;
    }
}
