.container {
    background: var(--primary-background);
    position: relative;
    z-index: 100;
    overflow-x: hidden;
}



.container::after {
    position: absolute;
    content: url('./assets/img/new/form_bg.svg');
    width: 1290px;
    height: 1100px;
    left: 0;
    bottom:10%;
    display: block;
    z-index: -2;
}


@media only screen and (max-width: 640px) and (min-width: 320px) {

    .container::after {
        display: none;
    }


}






