tr {
    border-radius: 32px !important;
}

.ant-layout {
    background-color: transparent !important;
}

div.ant-table, .ant-table-content > table, span.ant-table-column-title, th.ant-table-cell {
    font-size: 14px !important;
    font-family: var(--bingopay-font);
    background-color: transparent !important;
}

.ant-table-row {
    background-color: white !important;
    font-size: 14px;
    height: 20px !important;
}

.ant-table-cell {
    background-color: transparent !important;
}

.custom-table-row-active {
    background-color: var(--table-item-active) !important;
}

.ant-menu-item-selected, .ant-menu-item-active {
    color: var(--nav-item-link-color) !important;
}


/*TABLE PAGINATION*/

ul.ant-pagination {
    flex-shrink: unset !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

li.ant-pagination-item, li.ant-pagination-prev, li.ant-pagination-next {
    font-family: var(--bingopay-font) !important;
    border-radius: 50% !important;
    padding:0 !important;
    margin:0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.ant-pagination-item-link {
    font-size: 20px !important;
}

li.ant-pagination-item > a {
    margin:0 !important;
    padding:0 !important;
}

.ant-pagination-item-link:hover {
    background-color: unset !important;
}

.ant-pagination-item-active {
    background-color: #DF853B !important;
    border-color: #DF853B !important;
    color: #FFFFFF !important;
}

.ant-pagination-item:hover, .ant-pagination-prev:hover, .ant-pagination-next:hover {
    border-color: #DF853B !important;
    background-color: #DF853B !important;
    color: #FFFFFF !important;
}

.ant-pagination-item-active > a {
    color: white !important;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-options {
    background-color: transparent !important;
}

.ant-pagination-prev > button, .ant-pagination-next > button {
    background-color: transparent !important;
}

/*MOBILE STYLES*/

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    ul.ant-pagination {
        flex-shrink: unset !important;
        justify-content: center !important;
        align-items: center !important;
    }


}
