.ant-steps .ant-steps-item-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 90px;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    position: relative;
    display: block;
    width: 170px;
    height: 42px;
    padding: 10px;
    margin: -10px;
    border-radius: 20px;
    background: #DF853B;
    color: #ffffff;
}
.ant-steps.ant-steps-dot .ant-steps-item-tail, :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 83px;
}
.ant-steps.ant-steps-dot .ant-steps-item-content {
    display: block;
    width: 170px;
    height: 42px;
    padding: 10px;
    border-radius: 20px;
    background: #FEE7D5;
    color: #ffffff;
}
:where(.css-htwhyh).ant-btn-primary {
    display: block;
    width: 170px;
    height: 42px;
    padding: 10px;
    border-radius: 20px;
    background: #DF853B;
    color: #ffffff;
}
:where(.css-htwhyh).ant-btn-default,
:where(.css-htwhyh).ant-btn-default:not(:disabled):hover{
    display: block;
    width: 170px;
    height: 42px;
    padding: 10px;
    border-radius: 20px;
    color: #DF853B;
    background: transparent;
    border: 1px solid #DF853B;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
    background: #DF853B;
    display: block;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
    background: #FEE7D5;
    display: block;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #DF853B;
    display: block;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
    background: #DF853B;
    display: block;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #DF853B;
    display: block;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after,
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after
{
    background-color: #FEE7D5;
    display: block;
}
.ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
    position: absolute;
    top: -7px;
    inset-inline-start: -7px;
    background: transparent;
    content: "";
    width: 24px;
    height: 24px;
    border: 1px solid #fcceaa;
    border-radius: 50%;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-btn-primary,
:where(.css-dev-only-do-not-override-htwhyh).ant-btn-primary:not(:disabled):hover
{
    display: block;
    width: 220px;
    height: 40px;
    border-radius: 26px;
    border: 1px solid #DF853B;
    color: #ffffff;
    background-color: #DF853B;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-btn-default {
    display: block;
    width: 220px;
    height: 40px;
    border-radius: 26px;
    background: #DF853B;
    color: #FFFFFF;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-btn-default,
:where(.css-dev-only-do-not-override-htwhyh).ant-btn-default:not(:disabled):hover
{
    width: 220px;
    height: 40px;
    border-radius: 26px;
    border: 1px solid #DF853B;
    color: #DF853B;
    background-color: transparent;
}
:where(.css-dev-only-do-not-override-htwhyh) a,
:where(.css-dev-only-do-not-override-htwhyh) a:hover
{
    color: #3f3f3f;
}
:where(.css-htwhyh).ant-btn-primary:not(:disabled):hover {
    background-color: #DF853B;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 620px) {
    .ant-steps .ant-steps-item-container {
        height: fit-content;
    }
    :where(.css-htwhyh).ant-steps.ant-steps-vertical,
    :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical {
        flex-direction: row;
    }
    .ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
    .ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
        display: none;
    }
    .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
        display: none;
    }
    .ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        display: none;
    }
    .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after,
    .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
        display: none;
    }
    :where(.css-htwhyh).ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
        display: none;
    }
        :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical >.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
        display: none;
    }
    :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
        display: none;
    }
    :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        display: none;
    }
    :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-content,
    :where(.css-htwhyh).ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
        width: 80px;
    }
    .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
        width: 80px;
        height: 47px;
        padding: 10px 25px;
    }
    :where(.css-htwhyh).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
    :where(.css-dev-only-do-not-override-htwhyh).ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-title,
    :where(.css-htwhyh).ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
        display: flex;
        justify-content: center;
    }
    .anticon svg {
        width: 100%;
        height: 100%;
    }
}
