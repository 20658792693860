.layout {
    height: 100vh;
    display: grid;
    grid-template-rows: 12% 88%;
}

.aside {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    border-right: 1px solid var(--gray-medium);
    z-index: 2;
    position: fixed;
    overflow: hidden;
    height: 100%;
    width:20%;
    transition: all 0.1s linear;
    padding: 20px;
    background-color: var(--primary-background);
}

.header {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    padding: 25px 0 25px 0;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    position: fixed;

    background: var(--primary-background);
    border-bottom: 1px solid var(--gray-medium);
    font-family: var(--bingopay-font);

}

.main {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    background-color: var(--primary-background);
    overflow-x: hidden;
}

.menu {
    margin-top: 100px;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    width: 100%;
    height: 100%;
}

.mainWrapper {
    overflow: auto;
    display: flex;
    padding: 50px;
    background-color: var(--primary-background);
}

.logoWrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 25px;
    left: 25px;
    gap: 15px;
    z-index: 3;
}

.logoTitle {
    color: #3F3F3F;
    font-family: var(--bingopay-font);
    font-size: 24px;
    font-weight: 700;
}

.hidden {
    display: none;
}

.menuItem {
    font-family: var(--bingopay-font);
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #ABABAB;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 14px;

    height: 72px;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
}

.menuItem:hover {
    border-radius: 10px;
    background-color: var(--gray-medium) !important;
    color: var(--nav-item-link-color) !important;
}

.menuItem:active {
    color: var(--nav-item-link-color) !important;
}

.coDetails {
    font-family: var(--bingopay-font);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ABABAB;
    position: absolute;
    bottom: 0;
}

.copyright {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.copyright * {
    width: 100%;
}



@media only screen and (max-width: 768px) {
    .link_wrapper {
        width: auto;
        margin-left: 20px;
    }

    .normal {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        margin: 0 30px 0 0;
        color: #ABABAB;
        padding: 0;
        text-decoration: none;
    }

    .active {
        color: #DF853B;
        border-bottom: 2px solid #DF853B;
    }
}


@media print {
    .link_wrapper {
        display: none;
    }
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 1024px) {

    .mainWrapper {
        overflow: hidden;
        display: flex;
        padding: 10px;
        background-color: var(--primary-background);
    }


    .menuItem {
        font-size: 16px;
        line-height: 20px;
        gap: 10px;
    }

    .menuItem:hover {
        background-color: transparent !important;
        color: var(--nav-item-link-color) !important;
    }

    .menuItem:active {
        background-color: transparent !important;
    }


    .menuItem.activeMenuItem:hover > span {
        color: #F6F6F6 !important;
    }

    .menuItem.activeMenuItem > *,
    .menuItem.activeMenuItem {
        background-color: transparent;
        color: #F6F6F6;
    }

    .logoTitle {
        display: none;
    }

    .coDetails {
        padding: 0 0 16px 24px;
    }
}
