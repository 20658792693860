.container {
    width: 70%;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 100px;
    font-family: "Cera Pro";
}
.container > * > h2 {
    margin-top: 20px;
}



.gap {
    margin-bottom: 70px;
}

code {
    color: #DF853B;
    background: rgba(0,0,0,.05);
}

.bg {
    background: rgba(0,0,0,.05);
}

.bg code {
    background: none;
}

td {
    padding: 0 16px;
}

th {
    padding: 16px 0 16px 16px;
}

@media only screen
and (min-device-width: 623px)
and (max-device-width: 1024px) {
    .container {
        width: 90%;
        position: static;
        z-index: -2;
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .container {
        width: 100%;
        margin: 0 auto;
        padding: 100px 20px 100px 20px;
        font-family: "Cera Pro";
    }

    code {
        color: #DF853B;
        background: rgba(0,0,0,.05);
        word-break: break-word;
    }
}
